import React, { useState } from 'react';
import axios from 'axios';
import '../App.css';

const Register = ({ onRegister }) => {
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const reg = await axios.post('https://api.simplefriend.ru/register', { username });
      const response = await axios.post('https://api.simplefriend.ru/auth/login');
      onRegister(response.data.username, response.data.id);
    } catch (err) {
      if (err.response && err.response.status === 400) {
        setError('Username already exists');
      } else {
        setError('An error occurred');
      }
    }
  };

  return (
    <div className="register-container">
      <h2 className="register-title">Тест на Дружбу</h2>
      <img src='https://telegra.ph/file/83690ad33b61cd8290705.png' alt='Question' className='friends-image' />
      <div className='test-advantages'>
        <div className='advantage'>
          <img className='advantage-img' src='https://telegra.ph/file/bc3fb5fead0b3a476e766.png'></img>
          <div className='advantage-text'>Создай свой тест на дружбу</div>
        </div>
        <div className='advantage'>
        <img className='advantage-img' src='https://telegra.ph/file/ac4a68b025d16d6ca3a83.png'></img>
          <div className='advantage-text'>Поделисть с друзьями</div>
        </div>
        <div className='advantage-end'>
        <img className='advantage-img' src='https://telegra.ph/file/eb08130d6377d39fe732c.png'></img>
          <div className='advantage-text'>Смотри результаты</div>
        </div>
      </div>
      <form className="register-form" onSubmit={handleSubmit}>
        <input
          type="text"
          className="register-input"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Введите имя"
          required
        />
        <button type="submit" className="register-button">Начать</button>
      </form>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default Register;
