import React from 'react';
import '../App.css';

const Question = ({ question, handleAnswerOptionClick, currentQuestion, totalQuestions }) => {
  return (
    <div className='question-container'>
      <img src={question.image} alt='Question' className='question-image' />
      <div className='question-count'>
          <h3>Вопрос {currentQuestion + 1} / {totalQuestions}</h3> 
      </div>
      <div className='question-text'>{question.questionText}</div>
      <div className='answer-section'>
        {question.answerOptions.map((option, index) => (
          <button
            key={index}
            className='answer-button'
            onClick={() => handleAnswerOptionClick(index)}
          >
            {option.answerText}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Question;
