import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../App.css';

const Home = ({ username, onLogout, id }) => {
  const [dictionaries, setDicts] = useState([]);
  const [test_exist, setTestExistance] = useState(false);
  const navigate = useNavigate();
  const [copyStatus, setCopyStatus] = useState(`https://simplefriend.ru/take-test/${id}`);

  useEffect(() => {
    const fetchTestExistence = async () => {
      try {
        const response = await axios.post('https://api.simplefriend.ru/test-exist', {
          id: id
        });
        if (response.data.exist) {
          setTestExistance(true);
        }
      } catch (err) {
        console.error('Error checking test existence', err);
      }

      try {
        const response = await axios.post('https://api.simplefriend.ru/get-results', {
          id: id
        });
        setDicts(response.data.results);
      } catch (err) {
        console.error('Error checking test existence', err);
      }
    };

    fetchTestExistence();
  }, [id]);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(`https://simplefriend.ru/take-test/${id}`)
      .then(() => setCopyStatus('✔️ Скопировано'))
      .catch(() => setCopyStatus('Copy error'));
  };

  return (
    <div className='home'>
      {!test_exist ? (
        <>
          <div className='score-section'>
          <div className='text-link'>
            Похоже, что у вас еще нет теста {':('}
          </div>
          <button className='register-button' onClick={() => navigate('/create-test')}>
            Создать тест
          </button>
          </div>
        </>
      ) : (
        <>
          <div className='link-container'>
            <h1 className='text-link'>
                Ваш тест готов!
            </h1>
            <div className='text-link'>
              ссылка на тест:
            </div>
            <div className='test-link'>
              <button 
                className='copy-button'
                onClick={handleCopyClick}
              >
                {copyStatus}
              </button>
            </div>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th>Имя</th>
                <th>Результат</th>
              </tr>
            </thead>
            <tbody>
              {dictionaries.map((item) => (
                <tr key={item.id}>
                  <td>{item.taked_name}</td>
                  <td>{item.percentage}%</td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* <button className='delete-test-button'>Delete Quiz</button> */}
        </>
      )}
    </div>
  );
};

export default Home;
