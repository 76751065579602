import React, { useState, useEffect } from 'react';
import Question from '../components/Question';
import { quizData } from '../components/QuizData';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../App.css';

function CreateTest({ id , username }) {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [userAnswers, setUserAnswers] = useState([]);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [copyStatus, setCopyStatus] = useState('Скопировать');

  useEffect(() => {
    const fetchTestExistence = async () => {
      try {
        const response = await axios.post('https://api.simplefriend.ru/test-exist', {
          id: id
        });
        setShowScore(response.data.exist);
      } catch (err) {
        console.error('Error checking test existence', err);
      }
    };

    fetchTestExistence();
  }, [id]);

  const handleAnswerOptionClick = async (index) => {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < quizData.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      const data = {
        name: username,
        id: id,
        answers: userAnswers
      };
      try {
        await axios.post('https://api.simplefriend.ru/create-test', data);
      } catch (err) {
        setError('An error occurred');
      }
      setShowScore(true);
    }
    const newAnswers = userAnswers.concat(index);
    setUserAnswers(newAnswers);
  };

  const totalQuestions = quizData.length;

  const handleCopyClick = () => {
    navigator.clipboard.writeText(`https://simplefriend.ru/take-test/${id}`)
      .then(() => setCopyStatus('✔️ Скопировано'))
      .catch(() => setCopyStatus('Ошибка при копировании'));
  };

  return (
    <div className='app'>
      {showScore ? (
        <div className='score-section'>
          <h1 className='question-count'>👥 Создать тест на дружбу</h1>
          <div className='text-link' id="textToCopy">🔗 Ваша ссылка на тест</div>
          <div className='test-link'>
            <span>https://simplefriend.ru/take-test/{id}</span>
            <button 
              className='copy-button'
              onClick={handleCopyClick}
            >
              {copyStatus}
            </button>
            <button className='copy-button' onClick={() => navigate('/')}>
            🔙На главную
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className='question-section'>
            <Question
              question={quizData[currentQuestion]}
              handleAnswerOptionClick={(index) => handleAnswerOptionClick(index)}
              currentQuestion={currentQuestion}
              totalQuestions={totalQuestions}
            />
          </div>
        </>
      )}
      {error && <p className='error-message'>{error}</p>}
    </div>
  );
}

export default CreateTest;
