import React, { useState, useEffect } from 'react';
import Question from '../components/Question';
import { quizData } from '../components/QuizData';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import '../App.css';

function TakeTest({ user_id, username }) {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [userAnswers, setUserAnswers] = useState([]);
  const [creatorAnswers, setCreatorAnswers] = useState([]);
  const [error, setError] = useState('');
  const { test_id } = useParams();
  const [creator_username, setCreatorUsername] = useState('');
  const [test_exist, setTestExistance] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTestExistence = async () => {
      try {
        const response = await axios.post('https://api.simplefriend.ru/test-exist', {
          id: test_id
        });
        if (response.data.exist) {
          setCreatorAnswers(response.data.answers.answers);
          setCreatorUsername(response.data.answers.name_user);
        } else {
          setTestExistance(false);
        }
      } catch (err) {
        console.error('Error checking test existence', err);
      }
      try {
        const response = await axios.post('https://api.simplefriend.ru/result-exist', {
          creatorid: test_id,
          userid: user_id
        });
        if (response.data.exist) {
          setUserAnswers(response.data.answers.answers);
          setShowScore(response.data.exist);
        }
      } catch (err) {
        console.error('Error checking test existence', err);
      }
    };

    fetchTestExistence();
  }, [test_id, user_id]);

  const handleAnswerOptionClick = async (index) => {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < quizData.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      try {
        const data = {
          name: username,
          id: user_id,
          answers: userAnswers,
          creator_id: test_id,
          percentage: calculateMatchPercentage(creatorAnswers, userAnswers)
        };
        await axios.post('https://api.simplefriend.ru/create-result', data);
      } catch (err) {
        setError('An error occurred');
      }
      setShowScore(true);
    }
    const newAnswers = userAnswers.concat(index);
    setUserAnswers(newAnswers);
  };

  const totalQuestions = quizData.length;

  const calculateMatchPercentage = (arr1, arr2) => {
    let matchCount = 0;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] === arr2[i]) {
        matchCount++;
      }
    }
    const matchPercentage = (matchCount / arr1.length) * 100;
    return Math.round(matchPercentage);
  };

  const getColorForPercentage = (percentage) => {
    // Цвет от красного к зеленому
    const hue = ((percentage / 100) * 120).toString(10);
    return `hsl(${hue}, 100%, 50%)`;
  };

  const barScore = calculateMatchPercentage(creatorAnswers, userAnswers)

  return (
    <div className='app'>
      {!test_exist ? (
        <div>У данного пользователя еще нет теста</div>
      ) : showScore ? (
        <div>   
          <div className='result-text'>
            <h1 className='question-count'>На сколько вы хорошо знаете {creator_username}</h1>
            <h2>Ваш результат:</h2>
            <div className="result-bar" style={{ '--percentage': `${barScore}%`, backgroundColor: getColorForPercentage(barScore) }}>
                      {barScore}%
            </div>
            <button className='copy-button' onClick={() => navigate('/')}>
              На главную
            </button>
          </div>
        </div>
      ) : (
        <div className='question-section'>
          <Question
            question={quizData[currentQuestion]}
            handleAnswerOptionClick={(index) => handleAnswerOptionClick(index)}
            currentQuestion={currentQuestion}
            totalQuestions={totalQuestions}
          />
        </div>
      )}
      {error && <p className='error-message'>{error}</p>}
    </div>
  );
}

export default TakeTest;
