import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import axios from 'axios';
import Register from './components/Register';
import Home from './components/Home';
import CreateTest from './pages/CreateTest';
import TakeTest from './pages/TakeTest'

import './App.css';


const App = () => {
  const [username, setUsername] = useState(null);
  const [id, setID] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    axios.get('https://api.simplefriend.ru/user')
      .then(response => {
        setUsername(response.data.username);
        setID(response.data.id)
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
    })


  const handleRegister = (name, id) => {
    setUsername(name);
    setID(id);
  };

  const handleLogout = () => {
    document.cookie = 'auth_token=; Max-Age=0';
    setUsername(null);
    setID(null)
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/register" element={id ? <Navigate to="/create-test" id={id} username={username}/> : <Register onRegister={handleRegister} />} />
          <Route path="/" element={id ? <Home username={username} onLogout={handleLogout} id={id} /> : <Navigate to="/create-test"/>} />
          <Route path="/create-test" element={id ? <CreateTest id={id} username={username}></CreateTest> : <Register onRegister={handleRegister}/>}/>
          <Route path='/take-test/:test_id' element={id ? <TakeTest user_id={id} username={username}/> : <Register onRegister={handleRegister}/>}/>
        </Routes>
      </div>
    </Router>
  );
};

export default App;