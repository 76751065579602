export const quizData = [
  {
      questionText: '👀 На какую букву начинается твоя фамилия?',
      image: 'https://telegra.ph/file/974964c184ea8bbf0425e.jpg',
      answerOptions: [
          { answerText: '💫 А-Б-В' },
          { answerText: '💫 Г-Д-Е' },
          { answerText: '💫 Ё-Ж-З' },
          { answerText: '💫 И-Й-К' },
          { answerText: '💫 Л-М-Н' },
          { answerText: '💫 О-П-Р' },
          { answerText: '💫 С-Т-У' },
          { answerText: '💫 Ф-Х-Ц' },
          { answerText: '💫 Ч-Ш-Щ' },
          { answerText: '💫 Э-Ю-Я' },
      ],
  },
  {
      questionText: '❓Сколько тебе лет?',
      image: 'https://telegra.ph/file/ec3c0e8334d5efce9a21a.jpg',
      answerOptions: [
          { answerText: '👦 7-10' },
          { answerText: '🧸 11' },
          { answerText: '🎈 12' },
          { answerText: '🧢 13' },
          { answerText: '👟 14' },
          { answerText: '🕶 15' },
          { answerText: '🙈 16' },
          { answerText: '😈 17' },
          { answerText: '👑 18' },
          { answerText: '🗿 19' },
          { answerText: '🎩 20-100' },
      ],
  },
  {
      questionText: '😎 Есть ли у тебя братья и/или сестры?',
      image: 'https://telegra.ph/file/f2004929758f270846094.jpg',
      answerOptions: [
          { answerText: '✅ Да' },
          { answerText: '🙅‍♀️ Нет' },
      ],
  },
  {
      questionText: '✏️ Где ты сейчас учишься: в школе или в колледже/университете?',
      image: 'https://telegra.ph/file/6dd51ad76e548b7a7b0ff.jpg',
      answerOptions: [
          { answerText: '🎒 В школе' },
          { answerText: '🎓 В колледже/унике' },
      ],
  },
  {
      questionText: '📱Как ты общаешься больше всего?',
      image: 'https://telegra.ph/file/4e688dd8b70e897329d33.jpg',
      answerOptions: [
          { answerText: '💬 Сообщениями' },
          { answerText: '🎤 Голосовыми' },
          { answerText: '🎥 Видео-кружочками' },
          { answerText: '📞 По звонку' },
          { answerText: '👋 Лично' },
      ],
  },
  {
      questionText: '🙄 Ты за ЗОЖ(здоровый образ жизни) или нет?',
      image: 'https://telegra.ph/file/699f6aa489e25fac7d1e7.jpg',
      answerOptions: [
          { answerText: '👍 Конечно' },
          { answerText: '😈 Нет' },
      ],
  },
  {
      questionText: '🌄 Где ты живешь?',
      image: 'https://telegra.ph/file/620229d02fb18c8888951.jpg',
      answerOptions: [
          { answerText: '🏢 В квартире' },
          { answerText: '🏡 В доме' },
      ],
  },
  
];